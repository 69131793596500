<template>
  <Nav />
  <div class="support pt-100">

    <h1 class="text-center">This is an support page!!!</h1>
    <p>
      A Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit iure
      temporibus dolorem veniam obcaecati unde at quos ipsa similique, saepe
      vitae dolor rem nisi quae error, animi minus. Consectetur, animi!
    </p>
    <p>
      Es Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt amet,
      impedit cum eligendi et quidem quo suscipit unde eveniet veritatis quae
      nobis ex reiciendis neque enim mollitia dolorem doloremque ut perspiciatis
      repudiandae quam inventore, voluptates repellendus. Obcaecati mollitia
      rerum neque veritatis dignissimos odio doloremque ullam voluptates eum,
      laboriosam eligendi at fugiat natus impedit, quibusdam perferendis esse,
      odit reprehenderit quas. Odit possimus excepturi ducimus quod natus nulla,
      unde vero nostrum, molestias perferendis, fuga at! Voluptatibus rerum
      porro earum ipsum quisquam. Quo blanditiis recusandae consequuntur quidem
      facilis omnis, veritatis quibusdam rem nam a placeat tenetur voluptatem
      praesentium maxime, aliquam laboriosam fuga. Fugiat.
    </p>
    <p>
      We Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum hic ullam
      repellendus velit aperiam possimus nostrum? Itaque accusamus, sit
      provident explicabo quam excepturi ducimus suscipit non repellendus quo
      quae exercitationem debitis, dolore reprehenderit quia cum natus
      assumenda? Neque ex eum tenetur unde dicta doloremque, velit quae quia
      perspiciatis. Obcaecati rem odit, accusamus facilis voluptatum labore
      itaque quae rerum necessitatibus nisi esse a aut excepturi nesciunt
      tenetur vel quisquam blanditiis ipsam. Voluptas blanditiis sequi
      laudantium, beatae molestiae possimus corrupti, aperiam et officia in iste
      magni voluptatum! Officia, optio pariatur eveniet recusandae facilis
      molestias porro nisi voluptatibus accusantium praesentium. Ut voluptatum
      veritatis pariatur. Maiores quia numquam maxime asperiores quos nobis enim
      quaerat molestias perferendis fuga? Exercitationem inventore ipsa
      accusamus molestias ad laborum, aperiam aliquid ducimus! Dicta laborum
      ratione illum alias. Incidunt consequatur ut consequuntur quas enim
      accusamus suscipit molestiae tempora. Unde omnis laboriosam explicabo,
      quas similique molestiae debitis amet minus ex pariatur deleniti officiis
      accusamus cumque suscipit neque iusto incidunt voluptatem obcaecati
      adipisci provident illum voluptas magnam quam autem. Enim deleniti dolor
      est consequatur similique eligendi totam at quibusdam. Facere beatae
      veniam voluptas corrupti tempora nesciunt quaerat. Atque molestiae
      repellendus voluptatum quibusdam aliquid ipsum velit recusandae,
      accusantium omnis perspiciatis doloremque libero culpa.
    </p>
  </div>
</template>

<script>
import Nav from "@/components/Nav"
export default {
  name: "Support",
  components: {
    Nav
  },
  mounted(){

  },
  computed(){

  }

};
</script>
<style lang="css" scoped>
p {
  margin: 20px 5px 20px;
}
p::first-letter {
    font-size: 3em; /* Размер буквы */
    color: red; /* Цвет буквы */
    float: left; /* Выравнивание по левому краю */
    margin: 0 4px 2px 0; /* Отступы вокруг буквы */
   }
</style>

